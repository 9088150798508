import { Link } from "gatsby";
import React from "react";
import Helmet from "react-helmet";
import pic01 from "../assets/images/pic01.jpg";
import pic02 from "../assets/images/pic02.jpg";
import pic03 from "../assets/images/pic03.jpg";
import pic04 from "../assets/images/pic04.jpg";
import pic05 from "../assets/images/pic05.jpg";
import pic06 from "../assets/images/pic06.jpg";
import Banner from "../components/Banner";
import Layout from "../components/layout";

class HomeIndex extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Oud for All"
          meta={[
            {
              name: "description",
              content: "Learn oud online from a seasoned musician and teacher."
            },
            { name: "keywords", content: "oud, music, lesson, online" }
          ]}
        ></Helmet>

        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic01})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/oud"
                className="link primary"
              >
                <header className="major">
                  <h3>Oud</h3>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/about"
                className="link primary"
              >
                <header className="major">
                  <h3>About Me</h3>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="/lessons"
                className="link primary"
              >
                <header className="major">
                  <h3>Lessons</h3>
                </header>
              </Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <Link
                aria-label="Link to Landing Page"
                to="https://drive.google.com/drive/folders/1rJEoJPgyvcWWbSofpSjUhIfrXg2DFpm4?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="link primary"
              >
                <header className="major">
                  <h3>Music Score Archive</h3>
                </header>
              </Link>
            </article>
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Need some inspiration?</h2>
              </header>
              <p>
                Here is a playlist I created to let you listen to some of the
                best oud performances.
              </p>
              <ul className="actions">
                <li>
                  <Link
                    aria-label="Link to Landing Page"
                    to="https://www.youtube.com/watch?v=HbSEPd_CSA0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button next"
                  >
                    Go to the playlist
                  </Link>
                </li>
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default HomeIndex;
