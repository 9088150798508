import React from "react";

const Banner = props => (
  <section id="banner" className="major">
    <div className="inner">
      <header className="major">
        <h1>Hi, my name is Ozgur</h1>
      </header>
      <div className="content">
        <p>I teach oud to people from all skill levels.</p>
        <ul className="actions">
          <li>
            <a href="#one" name="Get Started" className="button next scrolly">
              Get Started
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
);

export default Banner;
